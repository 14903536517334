.video-wrapper { position: relative; }
.video-wrapper controls {
  position: absolute;
  display: flex;
  gap: 1rem;
  z-index: 1;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s;
}
.video-wrapper.paused controls,
.video-wrapper:hover controls,
.video-wrapper controls:has(button:focus-visible) {
  opacity: 1;
}

.video-wrapper controls button {
  position: relative;
  border: none;
  display: flex;
}

/* === Begin style resets === */
.video-wrapper controls input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}
.video-wrapper controls input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.video-wrapper controls input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}
.video-wrapper controls input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}
/* === End style resets === */
.video-wrapper controls .time-bar {
  display: block;
  border-radius: var(--border-radius);
  background: var(--color-neutral-light);
  flex-grow: 1;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
  color: transparent;
  user-select: none;
}
.video-wrapper controls .time-bar::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.5s;
  background: var(--color-brand);
  width: var(--value);
}
.video-wrapper controls .time-bar input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
/* Special styling for WebKit/Blink */
.video-wrapper controls input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: transparent;
  width: 0;
  height: 0;
  box-shadow: none;
}

/* All the same stuff for Firefox */
.video-wrapper controls input[type=range]::-moz-range-thumb {
  border: none;
  outline: none;
  background: transparent;
  width: 0;
  height: 0;
  box-shadow: none;
}

/* All the same stuff for IE */
.video-wrapper controls input[type=range]::-ms-thumb {
  border: none;
  outline: none;
  background: transparent;
  width: 0;
  height: 0;
  box-shadow: none;
}
