.projects-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  padding: 0;
  list-style-type: none;
}

.projects-list li {
  --padding: 1rem;
  position: relative;
}
.projects-list a {
  display: block;
  height: 100%;
  transition-duration: 0.2s;
  transition-property: color border-color outline-color;

  padding: var(--padding);

  text-decoration: none;
  color: var(--color-neutral-dark);

  outline-width: 0px;
  outline-style: solid;
  outline-color: var(--color-brand);
  border: 2px solid var(--color-neutral-dark);
  border-radius: var(--border-radius);
}
.projects-list a span {
  display: block;
  font-size: 1.5rem;
  font-weight: 800;
  margin-top: var(--padding);
}
.projects-list a:focus,
.projects-list a:hover {
  color: var(--color-brand);
  border-color: var(--color-brand);
  outline-width: 5px;
}

.h1-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.h1-wrapper button {
  background: var(--color-brand);
  color: var(--color-neutral-light);
  position: relative;
  border-radius: 50%;
}
.h1-wrapper button span {
  visibility: hidden;
  position: absolute;
}
.h1-wrapper button.table-of-contents-toggle svg { transition: opacity 0.2s; }
.h1-wrapper button.table-of-contents-toggle svg.open { position: absolute; }
.h1-wrapper button.table-of-contents-toggle.closed svg.close { opacity: 0; }
.h1-wrapper button.table-of-contents-toggle.open svg.open { opacity: 0; }

.image-and-links { position: relative; }
.file-download-links {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.file-download-links button svg,
.file-download-links button img { margin-right: 1rem; }
