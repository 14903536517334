.deck-box #research > .gallery {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.deck-box #research .gallery button img {
  min-width: 0;
  width: auto;
  height: auto;
  max-height: 15rem;
}
.deck-box #research #existing-solutions .gallery {
  display: grid;
  margin: 2rem 0;
  gap: 2rem;
}
.deck-box #research #existing-solutions .gallery.two { grid-template-columns: 1fr 1fr; }
.deck-box #research #existing-solutions .gallery.three { grid-template-columns: 1fr 1fr 1fr; }
.deck-box #research #existing-solutions .gallery img {
  width: 100%;
}

.deck-box #ideation .image { margin-top: 1rem; }

.deck-box #manila-paper ol {
  padding: 0;
  list-style-type: none;
}
.deck-box #manila-paper ol > li {
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  padding: 1rem;
  gap: 2rem;
  margin: 1rem 0;
}
.deck-box #manila-paper ol > li:last-child {
  border: 2px solid var(--color-brand);
}
.deck-box #manila-paper ol > li:not(:last-child) {
  border-radius: 0;
  border-left: 2px solid var(--color-brand);
}

.deck-box #manila-paper ol > li > div h4 {
  display: flex;
  border-radius: 0;
  border-bottom: 2px solid var(--color-neutral-dark);
  margin-bottom: 1rem;
  margin-top: 0rem;
  gap: 1rem;
  padding-bottom: 0.5rem;
}
.deck-box #manila-paper ol ul {
  padding-left: 0;
  list-style-position: inside;
}
@media (max-width: 899px) {
  .deck-box #manila-paper ol > li {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 700px) {
  .deck-box #manila-paper ol > li {
    grid-template-columns: 1fr 1fr;
  }
  .deck-box #manila-paper ol > li > *:first-child {
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
@media (max-width: 450px) {
  .deck-box #manila-paper ol > li {
    grid-template-columns: 1fr;
  }
}

.deck-box #cardboard > div {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
@media (max-width: 500px) {
  .deck-box #cardboard > div {
    grid-template-columns: 1fr;
  }
}

.deck-box #CAD > div {
  border-radius: 0;
  margin: 1rem 0;
  padding-left: 2rem;
  border-left: 2px solid var(--color-brand);
}
.deck-box #CAD h4 {
  display: block;
  width: fit-content;
  border-radius: 0;
  border-bottom: 2px solid var(--color-neutral-dark);
  margin-bottom: 1rem;
  gap: 1rem;
  padding-bottom: 0.5rem;
}
.deck-box #CAD > div > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
