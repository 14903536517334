.image {
  width: fit-content;
}
.image figure,
.image img { padding: 0; margin: 0; }

.image figcaption { margin: 1rem; }

.image button {
  padding: 0;
  background-color: transparent;
}
.image button > * { margin: 0; }
.image button:focus,
.image button:hover {
  color: var(--color-brand);
  outline: 5px solid var(--color-brand);
}

dialog {
  margin: auto;
  border: none;
}
dialog img {
  max-width: calc(100svw - 4rem);
  max-height: calc(100svh - 8rem);
}
dialog::backdrop {
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
}
dialog button {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
dialog button span {
  position: absolute;
  visibility: hidden;
}
.image dialog figcaption {
  font-size: 1.25rem;
  margin: 1rem 0 0 0;
}
