html {
  --color-neutral-light: #fffff3;
  --color-neutral-dark: #341f58;
  --color-brand: #f45330;

  --max-width: 1000px;

  --border-radius: 5px;

  font-family: helvetica, system-ui, sans-serif;
  font-size: 18px;

  background-color: var(--color-neutral-light);
  color: var(--color-neutral-dark);
}

* { border-radius: var(--border-radius); }

header > div,
main,
footer > div {
  margin: 2rem auto;
  max-width: min(var(--max-width), calc(100% - 4rem));
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

img, video {
  margin: 0.5rem 0;
}
h1, h2 {
  margin-top: 5rem;
  margin-bottom: 2rem;
}
h3, h4 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
section { margin: 2rem 0; }

button {
  background-color: var(--color-neutral-light);
  color: var(--color-dark);
  transition-property: background-color color;
  transition-duration: 0.2s;
  cursor: pointer;
  outline: none;
  border: none;
}
button:focus-visible,
button:hover {
  background-color: var(--color-brand);
  color: var(--color-neutral-light);
}

button.icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
button:not(.icon) {
  display: flex;
  padding: 1rem;
}

a:has(button) {
  text-decoration: none;
  color: inherit;
}
a {
  color: inherit;
}
p a {
  transition-property: color;
  transition-duration: 0.2s;
}
a:hover, a:focus { color: var(--color-brand); }
