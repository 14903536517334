.table-of-contents {
  margin: 2rem 0;
}

@media (max-width: calc(800px + (2 * (400px + 2rem)))) {
  .table-of-contents {
    transition-property: opacity max-height;
    transition-duration: 0.2s;
  }
  .table-of-contents.closed {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
  }
  .table-of-contents.open {
    max-height: 100vh;
    opacity: 1;
  }
}
@media (min-width: calc(800px + (2 * (400px + 2rem)))) {
  .table-of-contents {
    margin: 0;
    max-width: 400px;
    position: sticky;
    top: 2rem;
    transform: translateX(calc(-400px - 1rem));
    height: 0;
  }
  .h1-wrapper button.table-of-contents-toggle { display: none; }
}

.table-of-contents ul {
  list-style: none;
}
.table-of-contents li {
  margin: 5px;
}

.table-of-contents a {
  color: var(--color-neutral-dark);
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;

  transition-property: background-color color;
  transition-duration: 0.2s;

  display: block;
  width: fit-content;
}
.table-of-contents a:hover,
.table-of-contents a:focus {
  background-color: var(--color-neutral-dark);
  color: var(--color-neutral-light);
}
