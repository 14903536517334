.thimble #our-purpose {
  display: grid;
}

.thimble #our-purpose img {
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.thimble #how-it-works .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}
.thimble #how-it-works ol {
  list-style-type: none;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.thimble #how-it-works li {
  border: 2px solid var(--color-brand);
  padding: 1rem;
  border-radius: 5px;
  min-width: min(250px, 90svw);
  flex-basis: 40%;
  flex-grow: 1;
}
.thimble #how-it-works li h3 {
  border-radius: 0;
  border-bottom: 2px solid var(--color-neutral-dark);
  margin-bottom: 1rem;
  margin-top: 0;
}

.carousel ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}
.carousel button {
  outline: 2px solid transparent;
  background: transparent;
}
.carousel button:focus,
.carousel button:hover {
  outline-color: var(--color-brand);
  outline-width: 5px;
}
